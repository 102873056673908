import { Button, Form, Input, message, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { host } from "../../config";
import "./Login.css";
import { useState } from "react";
const App = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onFinish = (values) => {
    setLoading(true);
    console.log(loading);
    fetch(host + "/api/login", {
      method: "POST",
      body: JSON.stringify({
        username: values.username,
        password: values.password,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => {
        if (res.ok) {
          res
            .json()
            .then((data) => {
              message.success("Xush kelibsiz", 5);
              if (data.role === "sotuvchi") {
                localStorage.setItem("token", data.token);
                localStorage.setItem("role", data.role);
                navigate("/shop");
              } else {
                navigate("/dashboard");
              }
            })
            .catch((err) => message.error(err.message, 10));
        } else {
          message.error("Login yoki Parol xato", 10);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="card-login">
        <Form
          className="form-login"
          name="basic"
          initialValues={{
            remember: true,
          }}
          style={{
            maxWidth: 400,
            margin: "auto",
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <h1 className="title">Login</h1>
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                min: 6,
                message: "Uzunligi 6dan kichik bo'lmasin",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            {loading ? (
              <Spin />
            ) : (
              <Button type="primary" className="btn-login" htmlType="submit">
                Kirish
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default App;
