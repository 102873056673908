import {
    Badge,
    Card,
    Button,
    Modal,
    Form,
    Input,
    InputNumber,
    message,
} from "antd";
import HeaderShop from "../../components/HeaderShop/Header";
import "./Shop.css";
import { useState, useEffect } from "react";
import { host } from "../../config";
import { useNavigate } from "react-router-dom";

const App = () => {
    const [dollar, setDollar] = useState();
    const [gold, setGold] = useState();
    const [owner, setOwner] = useState();
    const [oldDollar, setOldDollar] = useState();
    const [name, setName] = useState();
    const [oldGold, setOldGold] = useState();
    const [oldRoom, setOldRoom] = useState();
    const [config, setConfig] = useState();
    const [data, setData] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    useEffect(() => {
        fetch(host + "/api/config", {
            method: "GET",
            headers: {
                Authorization: "Bareer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setConfig(res);
            })
            .catch((err) => console.log(err.message));
        fetch(host + "/api/room", {
            method: "GET",
            headers: {
                Authorization: "Bareer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setData(res);
            })
            .catch((err) => console.log(err.message));
    }, []);

    const UpdateGoldAndDollar = () => {
        fetch(host + "/api/room", {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: owner,
                dollar: dollar,
                gold: gold,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.message === "Invalid token.") {
                    navigate("/");
                }
            })
            .catch((err) => console.log(err.message));
    };

    const handleOk = (e) => {
        setIsModalOpen(false);
        fetch(host + "/api/room", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                name: name,
                gold: oldGold,
                dollar: oldDollar,
                summ: oldRoom,
            }),
        })
            .then((res) => {
                res.json();
            })
            .then((res) => window.location.reload())
            .catch((err) => message.error(err.name));
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    if (token === null) {
        return "404";
    } else if (!token) {
    } else {
        return (
            <>
                <HeaderShop config={config} />
                <Button
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                    style={{ margin: "30px" }}
                    type="primary"
                >
                    {" "}
                    Qo'shish
                </Button>
                <Modal
                    title="Yangi Mijoz"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                >
                    <Form>
                        <Form.Item label="Nomi" required>
                            <Input
                                placeholder="Nomi"
                                required={true}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Dollarda" required>
                            <InputNumber
                                type="number"
                                placeholder="Dollarda"
                                required={true}
                                onChange={(e) => setOldDollar(e)}
                            />
                        </Form.Item>
                        <Form.Item label="Lomda" required>
                            <InputNumber
                                type="number"
                                placeholder="Lomda"
                                required={true}
                                onChange={(e) => setOldGold(e)}
                            />
                        </Form.Item>
                        <Form.Item label="Ostatkasi" required>
                            <InputNumber
                                type="number"
                                placeholder="Ost."
                                required={true}
                                onChange={(e) => setOldRoom(e)}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
                <div className="container margin" style={{ margin: "30px" }}>
                    {data?.map((element) => {
                        return (
                            <div
                                className="hover-box-shadow"
                                onClick={(e) =>
                                    navigate(`/room/${element._id}`)
                                }
                            >
                                <Badge.Ribbon
                                    text={
                                        <div>
                                            {(
                                                element.clientsSum +
                                                element.summ +
                                                element.baseSum -
                                                element.gold
                                            ).toFixed(2)}
                                            gr
                                        </div>
                                    }
                                    color="orange"
                                >
                                    <Badge.Ribbon
                                        style={{
                                            marginTop: "30px",
                                        }}
                                        text={
                                            (
                                                ((element.clientsSum +
                                                    element.summ +
                                                    element.baseSum -
                                                    element.gold) *
                                                    config.gold_price) /
                                                config.dollar_price
                                            ).toFixed(2) + " $"
                                        }
                                        color="darkgreen"
                                    >
                                        <Card
                                            headStyle={{
                                                textAlign: "center",
                                                fontWeight: "bold",
                                                fontSize: "40px",
                                            }}
                                            title={element.name}
                                        >
                                            <div
                                                className="flex"
                                                style={{
                                                    fontSize: "18px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        margin: "0 auto",
                                                    }}
                                                >
                                                    Dollarda: $
                                                    <input
                                                        style={{
                                                            border: "none",
                                                            fontFamily:
                                                                "inherit",
                                                            fontSize: "inherit",
                                                            padding: "none",
                                                            textAlign: "center",
                                                            width: "80px",
                                                            margin: "0 auto",
                                                        }}
                                                        type="text"
                                                        onBlur={(e) =>
                                                            UpdateGoldAndDollar()
                                                        }
                                                        onChange={(e) => {
                                                            setDollar(
                                                                e.target.value,
                                                            );
                                                            setOwner(
                                                                element.name,
                                                            );
                                                        }}
                                                        defaultValue={
                                                            element.dollar
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        margin: "0 auto",
                                                    }}
                                                >
                                                    Lomda:
                                                    <input
                                                        style={{
                                                            border: "none",
                                                            fontFamily:
                                                                "inherit",
                                                            fontSize: "inherit",
                                                            padding: "none",
                                                            textAlign: "center",
                                                            width: "80px",
                                                        }}
                                                        type="text"
                                                        onBlur={(e) =>
                                                            UpdateGoldAndDollar()
                                                        }
                                                        onChange={(e) => {
                                                            setGold(
                                                                e.target.value,
                                                            );
                                                            setOwner(
                                                                element.name,
                                                            );
                                                        }}
                                                        defaultValue={
                                                            element.gold
                                                        }
                                                    />
                                                    gr
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "19px",
                                                    marginTop: "30px",
                                                }}
                                            >
                                                Mijozar:{" "}
                                                {element.clientsSum.toFixed(2)}{" "}
                                                gr
                                                <br />
                                                Room: {element.summ.toFixed(
                                                    2,
                                                )}{" "}
                                                gr <br />
                                                Baza:{" "}
                                                {element.baseSum.toFixed(
                                                    2,
                                                )} gr <br />
                                                <b>
                                                    Jami:{" "}
                                                    {(
                                                        element.clientsSum +
                                                        element.summ +
                                                        element.baseSum
                                                    ).toFixed(2)}
                                                </b>
                                            </div>
                                        </Card>
                                    </Badge.Ribbon>
                                </Badge.Ribbon>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }
};
export default App;
